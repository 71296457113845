export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'fr',
  messages: {
    en: {
      welcome: 'Welcome',
      error401: 'You must be logged in to access this page',
    },
    fr: {
      welcome: 'Bienvenue',
    error401: 'Vous devez être connecté pour accéder à cette page',
    }
  }
}))
